<template>
  <div class="documents" id="account">
    <div class="form-container">
      <h1>Заявки на покупку</h1>
      <div v-if="fundsBuyingList && fundsBuyingList.length > 0" class="form-container__form">
        <rwm-shares-card
          v-for="card in fundsBuyingList"
          :key="card.id"
          :title="card.name"
          :fund-status="card.status_id"
          :spec-reg="card.specreg.name || null"
          :id="card.id"
          :purchases="card.purchases"
          :if-qualified-investor="ifQualifiedInvestor"
          :if-fund-qual-investor="card.kvalInvestor"
          @download="download"
          @buy="goBuy(card)"
          @download-file="downloadFile"
        />
      </div>
      <div v-else>
        <h3>Вы еще не подавали заявки на покупку</h3>
      </div>
      <!--            <div class="form-container__info">-->
      <!--                <p class="anketa">-->
      <!--                    Это какой-то информационный текст для всех страниц с карточками. Лица могут быть признаны квалифицированными инвесторами, если они отвечают требованиям, установленным Федеральным законом "О рынке ценных бумаг"и принятыми в соответствии с ним нормативными актами Банка России.-->
      <!--                </p>-->
      <!--            </div>-->
    </div>
  </div>
</template>

<script>
// import RwmFundRequest from "../../../RWMFundRequest/RwmFundRequest";
import RwmSharesCard from "../../../RWMSharesCard/RwmSharesCard";
import RWMBuyPushModalMixin from "../../../mixins/RWMBuyPushModalMixin";

export default {
  name: "PurchasePage",
  mixins: [RWMBuyPushModalMixin],
  components: { RwmSharesCard },
  props: {
    fundsBuyingList: Array,
    activeFunds: Array,
    ifQualifiedInvestor: Boolean
  },
  methods: {
    goBuy(fond) {
      this.buy({
        toBuy: fond.id,
        fundStatus: fond.status_id,
        fundAnkets: fond.ankets,
        title: fond.name,
        fundKI: fond.kvalInvestor,
        hasNecessaryDocs: fond.fatca_status === 3 && fond.pod_ft_status === 3,
        fatca_status: fond.fatca_status,
        podft_status: fond.pod_ft_status,
        kiStatus: fond.appl_ci_status,
        isQualifiedInvestor: this.ifQualifiedInvestor
      });
    },
    download(data) {
      this.$emit("download", data);
    },
    downloadFile(file) {
      this.$emit("download-file", file);
    },
  },
};
</script>

<style scoped lang="scss">
.documents {
  & .form-container {
    &__form {
      flex-direction: column;
      & .funds-plate {
        @media screen and (min-width: 1310px) {
          flex: 0 1 calc(50% - 22px);
        }
        @media screen and (min-width: 1740px) {
          flex: 0 1 calc(33.3333% - 22px);
        }
      }
    }
  }
}
</style>
