<template>
  <purchase-page
      :funds-buying-list="getFundsBuyingList"
      :if-qualified-investor="getUSER.qualifiedInvestor"
      @download="download"
      @download-file="downloadFile"
  />
</template>

<script>
import PurchasePage from "../../../components/pages/cabinet/Funds/PurchasePage";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "PurchaseView",
  components: { PurchasePage },
  watch: {
    docChanged() {
      if (this.changedDocAlias === "appl-purchase_invest_shares") {
        this.GET_FUNDS();
      }
    },
    fondChanged() {
      this.GET_FUNDS();
    },
  },
  computed: {
    ...mapGetters("FundsModule", ["getFundsBuyingList"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("AutoUpdateModule", ["docChanged", "changedDocAlias", "fondChanged"])
  },
  methods: {
    ...mapActions("FundsModule", ["GET_FUNDS"]),
    ...mapActions("DocumentsModule", ["DOWNLOAD_DOCUMENT"]),
    ...mapActions("regModule", ["DOWNLOAD_FILE"]),
    download(data) {
      this.DOWNLOAD_DOCUMENT({
        id: data.id,
        name: `${data.name}.${data.type}`,
      });
    },
    downloadFile(file) {
      this.DOWNLOAD_FILE(file)
    }
  },
  mounted() {
    this.GET_FUNDS();
  },
};
</script>
